import { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  getUserInfo,
  getCustomerKtpSeva as getCustKTPSeva,
  postCheckReferralCode,
  getCustomerSpouseKtpSeva as getCustSpouseKTPSeva,
  getAvailableNIK,
  postSaveKtp,
  postSaveKtpSpouse,
  postDeleteAccount,
  postUpdateProfile,
  putUpdateKtpCity,
} from 'services/api'
import { encryptValue } from 'utils/handler/encryption'
import { LocalStorageKey, SessionStorageKey } from 'utils/enum'
import { getLocalStorage, saveLocalStorage } from 'utils/handler/localStorage'
import { removeInformationWhenLogout } from 'utils/handler/logout'
import { saveSessionStorage } from 'utils/handler/sessionStorage'
import { getToken } from 'utils/handler/auth'
import {
  CustomerKtpSeva,
  DeleteAccountRequestType,
  UTMTagsData,
  UpdateProfileType,
  updateKtpCityPayloadType,
} from 'utils/types/utils'
import { setAmplitudeUserId } from 'services/amplitude'
import ReportSentry from 'utils/handler/sentry'
import environments from 'utils/handler/getCurrentEnvironment'
import { collections } from 'services/api/collections'
import post from 'services/api/post'
import { CustomerRegister } from 'utils/types/models'

export const getCustomerInfoSeva = async (
  isUsingTempToken?: boolean,
  returnCatchedError?: boolean,
) => {
  try {
    const response = await getUserInfo(isUsingTempToken)
    return response
  } catch (error) {
    ReportSentry('Handler Customer - getCustomerInfoSeva', error)
    if (returnCatchedError) {
      return error
    } else {
      return null
    }
  }
}

export const getCustomerInfoWrapperSeva = async (
  isUsingTempToken?: boolean,
  returnCatchedError?: boolean,
) => {
  try {
    const response = await getCustomerInfoSeva(
      isUsingTempToken,
      returnCatchedError,
    )
    if (!!response && response.length > 0) {
      const customerId = response[0].id ?? ''
      const customerName = response[0].fullName ?? ''
      saveLocalStorage(
        LocalStorageKey.CustomerId,
        encryptValue(customerId.toString()),
      )
      saveLocalStorage(LocalStorageKey.CustomerName, encryptValue(customerName))
      saveSessionStorage(
        SessionStorageKey.CustomerId,
        encryptValue(customerId.toString()),
      )
      setAmplitudeUserId(response[0].phoneNumber ?? '')
    }

    return response
  } catch (err: any) {
    ReportSentry(`Handler Customer - getCustomerInfoWrapperSeva`, err)
    if (err?.response?.status === 400) {
      removeInformationWhenLogout()
    }
    if (returnCatchedError) {
      return err
    }
  }
}

export const getCustomerKtpSeva = () => {
  return getCustKTPSeva({
    headers: {
      Authorization: getToken()?.idToken,
    },
  })
}

export const getCustomerSpouseKtpSeva = () => {
  return getCustSpouseKTPSeva({
    headers: {
      Authorization: getToken()?.idToken,
    },
  })
}

export const checkReferralCode = (
  refcode: string,
  phoneNumber: string,
): Promise<
  AxiosResponse<{
    data: any
  }>
> => {
  return postCheckReferralCode(
    {
      refcode,
      phoneNumber,
    },
    {
      headers: {
        Authorization: getToken()?.idToken,
      },
    },
  )
}

export const checkNIKAvailable = (nik: string) => {
  const params = new URLSearchParams()
  params.append('nik', nik)
  return getAvailableNIK({ params })
}

export const saveKtp = (data: CustomerKtpSeva, config?: AxiosRequestConfig) => {
  return postSaveKtp(
    { ...data },
    { ...config, headers: { Authorization: getToken()?.idToken } },
  )
}

export const saveKtpSpouse = (
  data: CustomerKtpSeva,
  config?: AxiosRequestConfig,
) => {
  return postSaveKtpSpouse(
    { spouseKtpObj: { ...data }, isSpouse: true },
    { ...config, headers: { Authorization: getToken()?.idToken } },
  )
}

export const updateKtpCity = (
  data: updateKtpCityPayloadType,
  config?: AxiosRequestConfig,
) => {
  return putUpdateKtpCity({ ...data }, { ...config })
}

export const deleteAccount = (
  payload: DeleteAccountRequestType,
  config?: AxiosRequestConfig,
) => {
  return postDeleteAccount(
    {
      createdBy: payload.createdBy,
    },
    { ...config, headers: { Authorization: getToken()?.idToken } },
  )
}

export const updateProfile = (
  data: UpdateProfileType,
  config?: AxiosRequestConfig,
) => {
  return postUpdateProfile(data, {
    ...config,
    headers: { Authorization: getToken()?.idToken },
  })
}

export const fetchCustomerDetails = async (): Promise<any | null> => {
  try {
    const responseCustomerInfo: any = await getCustomerInfoWrapperSeva()
    return responseCustomerInfo
  } catch (e) {
    ReportSentry(`httpUtils - fetchCustomerDetails`, e)
    return null
  }
}

export const fetchCustomerName = async (): Promise<string | null> => {
  try {
    const responseCustomerInfo: any = await getCustomerInfoWrapperSeva()
    return responseCustomerInfo[0].fullName
  } catch (e) {
    ReportSentry(`httpUtils - fetchCustomerName`, e)
    return null
  }
}

export const fetchCustomerKtp = async (): Promise<any | null> => {
  try {
    const responseCustomerInfo: any = await getCustomerKtpSeva()
    return responseCustomerInfo.data
  } catch (e) {
    ReportSentry(`httpUtils - fetchCustomerKtp`, e)
    return null
  }
}

export const fetchCustomerSpouseKtp = async (): Promise<any | null> => {
  try {
    const responseCustomerInfo: any = await getCustomerSpouseKtpSeva()
    return responseCustomerInfo.data
  } catch (e) {
    ReportSentry(`httpUtils - fetchCustomerSpouseKtp`, e)
    return null
  }
}

export const registerCustomerSeva = (
  {
    phoneNumber,
    fullName,
    dob,
    gender,
    email,
    promoSubscription,
    marital,
    referralCode,
  }: CustomerRegister,
  isUsingTempToken?: boolean,
) => {
  return post(
    collections.auth.createCustomer,
    {
      phoneNumber,
      fullName,
      dob,
      gender,
      email,
      promoSubscription,
      marital,
      referralCode,
    },
    {
      headers: {
        Authorization: getToken(isUsingTempToken)?.idToken,
      },
    },
  )
}

export const getPAAIAInfo = (orderId: string) => {
  return post(
    collections.leads.paIaInfo + orderId,
    {},
    {
      headers: {
        Authorization: getToken()?.idToken,
      },
    },
  )
}

export const sendRefiContact = async (
  phoneNumber?: string,
  fullName?: string,
  source?: string,
  isLogin?: boolean,
  temanSevaTrxCode?: string,
) => {
  const UTMTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
  const payload = {
    phoneNumber: phoneNumber,
    name: fullName,
    source: source,
    isLogin: isLogin,
    temanSevaTrxCode: temanSevaTrxCode,
    utmSource: UTMTags?.utm_source,
    utmMedium: UTMTags?.utm_medium,
    utmCampaign: UTMTags?.utm_campaign,
    utmId: UTMTags?.utm_id,
    utmContent: null, // temporary
    utmTerm: UTMTags?.utm_term,
    adSet: UTMTags?.adset,
  }
  const { AES } = (await import('crypto-js')).default
  const encryptedPayload = AES.encrypt(
    JSON.stringify(payload),
    process.env.NEXT_PUBLIC_LEAD_PAYLOAD_ENCRYPTION_KEY ?? '',
  ).toString()

  const config = {
    headers: {
      'torq-api-key': environments.unverifiedLeadApiKey,
      'Content-Type': 'text/plain',
    },
  }

  return post(collections.refinancing.sendRefiContact, encryptedPayload, config)
}

export const sendRefiQuestion = async (
  question?: string,
  contactId?: string,
) => {
  const { AES } = (await import('crypto-js')).default
  const encryptedPayload = AES.encrypt(
    JSON.stringify({
      question: question,
      contactId: contactId,
    }),
    process.env.NEXT_PUBLIC_LEAD_PAYLOAD_ENCRYPTION_KEY ?? '',
  ).toString()

  const config = {
    headers: {
      'torq-api-key': environments.unverifiedLeadApiKey,
      'Content-Type': 'text/plain',
    },
  }

  return post(
    collections.refinancing.sendRefiQuestion,
    encryptedPayload,
    config,
  )
}
