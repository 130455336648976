export enum CountlyEventNames {
  WEB_HAMBURGER_OPEN = 'WEB_HAMBURGER_OPEN',

  // LEADS
  WEB_LEADS_FORM_BUTTON_CLICK = 'WEB_LEADS_FORM_BUTTON_CLICK',
  WEB_LEADS_FORM_NAME_CLICK = 'WEB_LEADS_FORM_NAME_CLICK',
  WEB_LEADS_FORM_PHONE_NUMBER_CLICK = 'WEB_LEADS_FORM_PHONE_NUMBER_CLICK',
  WEB_LEADS_FORM_SEND_CLICK = 'WEB_LEADS_FORM_SEND_CLICK',
  WEB_OTP_VIEW = 'WEB_OTP_VIEW',
  WEB_OTP_CLICK = 'WEB_OTP_CLICK',
  WEB_OTP_RESEND_CLICK = 'WEB_OTP_RESEND_CLICK',
  WEB_LEADS_FORM_SUCCESS_VIEW = 'WEB_LEADS_FORM_SUCCESS_VIEW',
  WEB_WA_DIRECT_CLICK = 'WEB_WA_DIRECT_CLICK',

  // INSTANT APPROVAL
  WEB_INSTANT_APPROVAL_FINANCING_COMPANY_VIEW = 'WEB_INSTANT_APPROVAL_FINANCING_COMPANY_VIEW',
  WEB_INSTANT_APPROVAL_FINANCING_COMPANY_CLICK = 'WEB_INSTANT_APPROVAL_FINANCING_COMPANY_CLICK',
  WEB_INSTANT_APPROVAL_FINANCING_SK_CLICK = 'WEB_INSTANT_APPROVAL_FINANCING_SK_CLICK',
  WEB_INSTANT_APPROVAL_FINANCING_CTA_CLICK = 'WEB_INSTANT_APPROVAL_FINANCING_CTA_CLICK',
  WEB_INSTANT_APPROVAL_PAGE_CONFIRMATION_VIEW = 'WEB_INSTANT_APPROVAL_PAGE_CONFIRMATION_VIEW',
  WEB_INSTANT_APPROVAL_PAGE_CONFIRMATION_CONTINUE_CLICK = 'WEB_INSTANT_APPROVAL_PAGE_CONFIRMATION_CONTINUE_CLICK',
  WEB_INSTANT_APPROVAL_PAGE_FINISH_VIEW = 'WEB_INSTANT_APPROVAL_PAGE_FINISH_VIEW',
  WEB_INSTANT_APPROVAL_PAGE_FINISH_OTHER_CAR_CLICK = 'WEB_INSTANT_APPROVAL_PAGE_FINISH_OTHER_CAR_CLICK',
  WEB_INSTANT_APPROVAL_DOWNLOAD_APP_CLICK = 'WEB_INSTANT_APPROVAL_DOWNLOAD_APP_CLICK',
  WEB_INSTANT_APPROVAL_PAGE_RESULT_VIEW = 'WEB_INSTANT_APPROVAL_PAGE_RESULT_VIEW',
  WEB_INSTANT_APPROVAL_PAGE_FINISH_BACK_TO_HOMEPAGE_CLICK = 'WEB_INSTANT_APPROVAL_PAGE_FINISH_BACK_TO_HOMEPAGE_CLICK',
  WEB_INSTANT_APPROVAL_POPUP_VIEW = 'WEB_INSTANT_APPROVAL_POPUP_VIEW',
  WEB_INSTANT_APPROVAL_POPUP_CONTINUE_CLICK = 'WEB_INSTANT_APPROVAL_POPUP_CONTINUE_CLICK',
  WEB_INSTANT_APPROVAL_POPUP_SKIP_CLICK = 'WEB_INSTANT_APPROVAL_POPUP_SKIP_CLICK',
  WEB_INSTANT_APPROVAL_POPUP_CLOSE_CLICK = 'WEB_INSTANT_APPROVAL_POPUP_CLOSE_CLICK',
  WEB_INSTANT_APPROVAL_PAGE_FINISH_TRANSACTION_HISTORY_CLICK = 'WEB_INSTANT_APPROVAL_PAGE_FINISH_TRANSACTION_HISTORY_CLICK',

  // MULTI KK
  WEB_MULTI_KK_PAGE_VIEW = 'WEB_MULTI_KK_PAGE_VIEW',
  WEB_MULTI_IA_PAGE_CAR_MIN_PRICE_CLICK = 'WEB_MULTI_IA_PAGE_CAR_MIN_PRICE_CLICK',
  WEB_MULTI_IA_PAGE_CAR_MAX_PRICE_CLICK = 'WEB_MULTI_IA_PAGE_CAR_MAX_PRICE_CLICK',
  WEB_MULTI_IA_PAGE_PRICE_SLIDER_PRICE_CLICK = 'WEB_MULTI_IA_PAGE_PRICE_SLIDER_PRICE_CLICK',
  WEB_MULTI_IA_PAGE_DP_CLICK = 'WEB_MULTI_IA_PAGE_DP_CLICK',
  WEB_MULTI_IA_PAGE_INCOME_CLICK = 'WEB_MULTI_IA_PAGE_INCOME_CLICK',
  WEB_MULTI_IA_PAGE_TENOR_CLICK = 'WEB_MULTI_IA_PAGE_TENOR_CLICK',
  WEB_MULTI_IA_PAGE_TRANSMISION_CLICK = 'WEB_MULTI_IA_PAGE_TRANSMISION_CLICK',
  WEB_MULTI_IA_PAGE_OCCUPATION_CLICK = 'WEB_MULTI_IA_PAGE_OCCUPATION_CLICK',
  WEB_MULTI_IA_PAGE_REFERRAL_CLICK = 'WEB_MULTI_IA_PAGE_REFERRAL_CLICK',
  WEB_MULTI_IA_PAGE_RETRY_POPUP_VIEW = 'WEB_MULTI_IA_PAGE_RETRY_POPUP_VIEW',
  WEB_MULTI_IA_PAGE_RETRY_POPUP_CTA_CLICK = 'WEB_MULTI_IA_PAGE_RETRY_POPUP_CTA_CLICK',
  WEB_MULTI_IA_PAGE_RETRY_POPUP_CLOSE_CLICK = 'WEB_MULTI_IA_PAGE_RETRY_POPUP_CLOSE_CLICK',
  WEB_MULTI_IA_PAGE_CTA_CLICK = 'WEB_MULTI_IA_PAGE_CTA_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_VIEW = 'WEB_MULTI_KK_PAGE_RESULT_VIEW',
  WEB_MULTI_KK_PAGE_RESULT_COACHMARK_VIEW = 'WEB_MULTI_KK_PAGE_RESULT_COACHMARK_VIEW',
  WEB_MULTI_KK_PAGE_RESULT_COACHMARK_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_COACHMARK_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_LEARN_MORE_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_LEARN_MORE_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_SORT_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_SORT_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_SORT_OPTION_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_SORT_OPTION_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_IA_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_IA_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_PROMO_BADGE_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_PROMO_BADGE_CLICK',
  WEB_MULTI_KK_PAGE_RESULT_PROMO_POPUP_VIEW = 'WEB_MULTI_KK_PAGE_RESULT_PROMO_POPUP_VIEW',
  WEB_MULTI_KK_PAGE_RESULT_PROMO_SNK_CLICK = 'WEB_MULTI_KK_PAGE_RESULT_PROMO_SK_CLICK',
  WEB_MULTI_KK_PAGE_CAR_DETAIL_CLICK = 'WEB_MULTI_KK_PAGE_CAR_DETAIL_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_COMPANY_VIEW = 'WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_COMPANY_VIEW',
  WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_COMPANY_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_COMPANY_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_SK_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_SK_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_CTA_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_FINANCING_CTA_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_CONFIRMATION_VIEW = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_CONFIRMATION_VIEW',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_CONFIRMATION_CONTINUE_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_CONFIRMATION_CONTINUE_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_VIEW = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_VIEW',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_OTHER_CAR_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_OTHER_CAR_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_DOWNLOAD_APP_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_DOWNLOAD_APP_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_RESULT_VIEW = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_RESULT_VIEW',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_BACK_TO_HOMEPAGE_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_BACK_TO_HOMEPAGE_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_VIEW = 'WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_VIEW',
  WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_CONTINUE_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_CONTINUE_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_SKIP_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_SKIP_CLICK',
  WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_CLOSE_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_POPUP_CLOSE_CLICK',
  WEB_MULTI_KK_KTP_PAGE_PHOTO_VIEW = 'WEB_MULTI_KK_KTP_PAGE_PHOTO_VIEW',
  WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_TRANSACTION_HISTORY_CLICK = 'WEB_MULTI_KK_INSTANT_APPROVAL_PAGE_FINISH_TRANSACTION_HISTORY_CLICK',

  // CREDIT QUALIFICATION
  WEB_CREDIT_QUALIFICATION_FORM_PAGE_VIEW = 'WEB_CREDIT_QUALIFICATION_FORM_PAGE_VIEW',
  WEB_CREDIT_QUALIFICATION_FORM_PAGE_CAR_DETAIL_CLICK = 'WEB_CREDIT_QUALIFICATION_FORM_PAGE_CAR_DETAIL_CLICK',
  WEB_CREDIT_QUALIFICATION_FORM_PAGE_OCCUPATION_CLICK = 'WEB_CREDIT_QUALIFICATION_FORM_PAGE_OCCUPATION_CLICK',
  WEB_CREDIT_QUALIFICATION_FORM_PAGE_INCOME_CLICK = 'WEB_CREDIT_QUALIFICATION_FORM_PAGE_INCOME_CLICK',
  WEB_CREDIT_QUALIFICATION_FORM_PAGE_REFERRAL_CLICK = 'WEB_CREDIT_QUALIFICATION_FORM_PAGE_REFERRAL_CLICK',
  WEB_CREDIT_QUALIFICATION_FORM_PAGE_CTA_CLICK = 'WEB_CREDIT_QUALIFICATION_FORM_PAGE_CTA_CLICK',
  WEB_CREDIT_QUALIFICATION_REVIEW_PAGE_VIEW = 'WEB_CREDIT_QUALIFICATION_REVIEW_PAGE_VIEW',
  WEB_CREDIT_QUALIFICATION_PAGE_REVIEW_CTA_CLICK = 'WEB_CREDIT_QUALIFICATION_PAGE_REVIEW_CTA_CLICK',
  WEB_CREDIT_QUALIFICATION_RESULT_PAGE_VIEW = 'WEB_CREDIT_QUALIFICATION_RESULT_PAGE_VIEW',
  WEB_CREDIT_QUALIFICATION_RESULT_PAGE_LEARN_MORE_CLICK = 'WEB_CREDIT_QUALIFICATION_RESULT_PAGE_LEARN_MORE_CLICK',
  WEB_CREDIT_QUALIFICATION_RESULT_PAGE_CONTINUE_INSTANT_APPROVAL_CLICK = 'WEB_CREDIT_QUALIFICATION_RESULT_PAGE_CONTINUE_INSTANT_APPROVAL_CLICK',
  WEB_CREDIT_QUALIFICATION_RESULT_PAGE_POPUP_CHANGE_FINCAP_CLICK = 'WEB_CREDIT_QUALIFICATION_RESULT_PAGE_POPUP_CHANGE_FINCAP_CLICK',

  //PLP
  WEB_PLP_VIEW = 'WEB_PLP_VIEW',
  WEB_PLP_PRODUCT_CARD_CTA_CLICK = 'WEB_PLP_PRODUCT_CARD_CTA_CLICK',
  WEB_PLP_CAR_DETAIL_CLICK = 'WEB_PLP_CAR_DETAIL_CLICK',
  WEB_PLP_OPEN_FILTER_CLICK = 'WEB_PLP_OPEN_FILTER_CLICK',
  WEB_PLP_FILTER_APPLY_CLICK = 'WEB_PLP_FILTER_APPLY_CLICK',
  WEB_PLP_FILTER_RESET_CLICK = 'WEB_PLP_FILTER_RESET_CLICK',
  WEB_PLP_OPEN_SORT_CLICK = 'WEB_PLP_OPEN_SORT_CLICK',
  WEB_PLP_SORT_OPTION_CLICK = 'WEB_PLP_SORT_OPTION_CLICK',
  WEB_PLP_FINCAP_BADGE_CLICK = 'WEB_PLP_FINCAP_BADGE_CLICK',
  WEB_PLP_FINCAP_BANNER_DESC_VIEW = 'WEB_PLP_FINCAP_BANNER_DESC_VIEW',
  WEB_PLP_FINCAP_BANNER_DESC_OK_CLICK = 'WEB_PLP_FINCAP_BANNER_DESC_OK_CLICK',
  WEB_PLP_FINCAP_BANNER_DESC_EXIT_CLICK = 'WEB_PLP_FINCAP_BANNER_DESC_EXIT_CLICK',
  WEB_PLP_FILTER_CARSPEC_EXPAND_CLICK = 'WEB_PLP_FILTER_CARSPEC_EXPAND_CLICK',
  WEB_PLP_FILTER_FINCAP_EXPAND_CLICK = 'WEB_PLP_FILTER_FINCAP_EXPAND_CLICK',

  // PDP
  WEB_PDP_VIEW = 'WEB_PDP_VIEW',
  WEB_PDP_LOAN_CALCULATOR_CTA_CLICK = 'WEB_PDP_LOAN_CALCULATOR_CTA_CLICK',
  WEB_PDP_VISUAL_TAB_CLICK = 'WEB_PDP_VISUAL_TAB_CLICK',
  WEB_PDP_MAIN_PHOTO_CLICK = 'WEB_PDP_MAIN_PHOTO_CLICK',
  WEB_PDP_CAROUSEL_PHOTO_CLICK = 'WEB_PDP_CAROUSEL_PHOTO_CLICK',
  WEB_PDP_BROCHURE_CLICK = 'WEB_PDP_BROCHURE_CLICK',
  WEB_PDP_OPEN_SHARE_CLICK = 'WEB_PDP_OPEN_SHARE_CLICK',
  WEB_PDP_TAB_CONTENT_CLICK = 'WEB_PDP_TAB_CONTENT_CLICK',
  WEB_PDP_VARIANT_LIST_LAYOUT_CLICK = 'WEB_PDP_VARIANT_LIST_LAYOUT_CLICK',
  WEB_PDP_VARIANT_PRICELIST_CLICK = 'WEB_PDP_VARIANT_PRICELIST_CLICK',
  WEB_PDP_FAQ_CLICK = 'WEB_PDP_FAQ_CLICK',
  WEB_PDP_SEO_TEXT_EXPAND_CLICK = 'WEB_PDP_SEO_TEXT_EXPAND_CLICK',
  WEB_PDP_CAR_ROTATE_CLICK = 'WEB_PDP_CAR_ROTATE_CLICK',
  WEB_PDP_VIDEO_CLICK = 'WEB_PDP_VIDEO_CLICK',
  WEB_PDP_SHARE_OPTION_CLICK = 'WEB_PDP_SHARE_OPTION_CLICK',
  WEB_PDP_COLOR_VARIATION_CLICK = 'WEB_PDP_COLOR_VARIATION_CLICK',

  // USED CAR
  WEB_USED_CAR_PLP_VIEW = 'WEB_USED_CAR_PLP_VIEW',
  WEB_USED_CAR_PDP_VIEW = 'WEB_USED_CAR_PDP_VIEW',
  WEB_USED_CAR_LC_VIEW = 'WEB_USED_CAR_LC_VIEW',

  //KTP
  WEB_KTP_PAGE_PHOTO_VIEW = 'WEB_KTP_PAGE_PHOTO_VIEW',
  WEB_KTP_PAGE_PHOTO_SUCCESS_VIEW = 'WEB_KTP_PAGE_PHOTO_SUCCESS_VIEW',
  WEB_KTP_PAGE_PHOTO_SUCCESS_RETAKE_CLICK = 'WEB_KTP_PAGE_PHOTO_SUCCESS_RETAKE_CLICK',
  WEB_KTP_PAGE_PHOTO_SUCCESS_CONTINUE_CLICK = 'WEB_KTP_PAGE_PHOTO_SUCCESS_CONTINUE_CLICK',
  WEB_KTP_EDIT_PAGE_VIEW = 'WEB_KTP_EDIT_PAGE_VIEW',
  WEB_KTP_PAGE_CONFIRMATION_VIEW = 'WEB_KTP_PAGE_CONFIRMATION_VIEW',
  WEB_KTP_PAGE_CONFIRMATION_SAME_CITY_CLICK = 'WEB_KTP_PAGE_CONFIRMATION_SAME_CITY_CLICK',
  WEB_KTP_PAGE_CONFIRMATION_DOMICILE_CITY_CLICK = 'WEB_KTP_PAGE_CONFIRMATION_DOMICILE_CITY_CLICK',
  WEB_KTP_PAGE_CONFIRMATION_ADD_SPOUSE_CLICK = 'WEB_KTP_PAGE_CONFIRMATION_ADD_SPOUSE_CLICK',
  WEB_KTP_PAGE_CONFIRMATION_CTA_CLICK = 'WEB_KTP_PAGE_CONFIRMATION_CTA_CLICK',
  WEB_KTP_PAGE_PHOTO_CAPTURE_CLICK = 'WEB_KTP_PAGE_PHOTO_CAPTURE_CLICK',
  WEB_KTP_PAGE_PHOTO_UPLOAD_CLICK = 'WEB_KTP_PAGE_PHOTO_UPLOAD_CLICK',
  WEB_KTP_PAGE_NIK_DOUBLED_POPUP_VIEW = 'WEB_KTP_PAGE_NIK_DOUBLED_POPUP_VIEW',
  WEB_KTP_PAGE_NIK_DOUBLED_POPUP_CTA_CLICK = 'WEB_KTP_PAGE_NIK_DOUBLED_POPUP_CTA_CLICK',
  WEB_KTP_PAGE_OCR_FAILED_READ_TOAST_MESSAGE_VIEW = 'WEB_KTP_PAGE_OCR_FAILED_READ_TOAST_MESSAGE_VIEW',
  WEB_KTP_EDIT_PAGE_NIK_CLICK = 'WEB_KTP_EDIT_PAGE_NIK_CLICK',
  WEB_KTP_EDIT_PAGE_NAME_CLICK = 'WEB_KTP_EDIT_PAGE_NAME_CLICK',
  WEB_KTP_EDIT_PAGE_CITY_CLICK = 'WEB_KTP_EDIT_PAGE_CITY_CLICK',
  WEB_KTP_EDIT_PAGE_MARITAL_STATUS_CLICK = 'WEB_KTP_EDIT_PAGE_MARITAL_STATUS_CLICK',
  WEB_KTP_EDIT_PAGE_DOB_CLICK = 'WEB_KTP_EDIT_PAGE_DOB_CLICK',
  WEB_KTP_EDIT_PAGE_CTA_CLICK = 'WEB_KTP_EDIT_PAGE_CTA_CLICK',

  // header
  WEB_HAMBURGER_LOGIN_REGISTER_CLICK = 'WEB_HAMBURGER_LOGIN_REGISTER_CLICK',
  WEB_HAMBURGER_MENU_EXPAND = 'WEB_HAMBURGER_MENU_EXPAND',
  WEB_HAMBURGER_MENU_CLICK = 'WEB_HAMBURGER_MENU_CLICK',
  WEB_HAMBURGER_ACCOUNT_CLICK = 'WEB_HAMBURGER_ACCOUNT_CLICK',
  WEB_SEVA_LOGO_CLICK = 'WEB_SEVA_LOGO_CLICK',
  WEB_CAR_SEARCH_ICON_CLICK = 'WEB_CAR_SEARCH_ICON_CLICK',
  WEB_CAR_SEARCH_ICON_FIELD_CLICK = 'WEB_CAR_SEARCH_ICON_FIELD_CLICK',
  WEB_CAR_SEARCH_ICON_SUGGESTION_CLICK = 'WEB_CAR_SEARCH_ICON_SUGGESTION_CLICK',
  WEB_CITY_SELECTOR_BANNER_VIEW = 'WEB_CITY_SELECTOR_BANNER_VIEW',
  WEB_CITY_SELECTOR_BANNER_CITY_FIELD_CLICK = 'WEB_CITY_SELECTOR_BANNER_CITY_FIELD_CLICK',
  WEB_CITY_SELECTOR_BANNER_CITY_CLICK = 'WEB_CITY_SELECTOR_BANNER_CITY_CLICK',
  WEB_CITY_SELECTOR_BANNER_FIND_CAR_CLICK = 'WEB_CITY_SELECTOR_BANNER_FIND_CAR_CLICK',
  WEB_CITY_SELECTOR_BANNER_LATER_CLICK = 'WEB_CITY_SELECTOR_BANNER_LATER_CLICK',
  WEB_CITY_SELECTOR_OPEN_CLICK = 'WEB_CITY_SELECTOR_OPEN_CLICK',
  WEB_CITY_SELECTOR_TOOLTIP_CLICK = 'WEB_CITY_SELECTOR_TOOLTIP_CLICK',

  // ANNOUNCEMENT BOX
  WEB_ANNOUNCEMENT_VIEW = 'WEB_ANNOUNCEMENT_VIEW',
  WEB_ANNOUNCEMENT_CLICK = 'WEB_ANNOUNCEMENT_CLICK',
  WEB_ANNOUNCEMENT_CLOSE_CLICK = 'WEB_ANNOUNCEMENT_CLOSE_CLICK',

  // PROMO
  WEB_PROMO_CLICK = 'WEB_PROMO_CLICK',
  WEB_HOMEPAGE_PROMO_BANNER_ALL_CLICK = 'WEB_HOMEPAGE_PROMO_BANNER_ALL_CLICK',
  WEB_HOMEPAGE_PROMO_BANNER_CLICK = 'WEB_HOMEPAGE_PROMO_BANNER_CLICK',
  WEB_HOMEPAGE_LAYANAN_LAIN_CLICK = 'WEB_HOMEPAGE_LAYANAN_LAIN_CLICK',
  WEB_PROMO_SK_CLICK = 'WEB_PROMO_SK_CLICK',
  WEB_KLAIM_PROMO_PAGE_VIEW = 'WEB_KLAIM_PROMO_PAGE_VIEW',
  WEB_KLAIM_PROMO_SUCCESS_PAGE_VIEW = 'WEB_KLAIM_PROMO_SUCCESS_PAGE_VIEW',
  WEB_KLAIM_PROMO_FORM_PAGE_VIEW = 'WEB_KLAIM_PROMO_FORM_PAGE_VIEW',

  // ARTICLE
  WEB_ARTICLE_ALL_CLICK = 'WEB_ARTICLE_ALL_CLICK',
  WEB_ARTICLE_CLICK = 'WEB_ARTICLE_CLICK',
  WEB_ARTICLE_TAB_CLICK = 'WEB_ARTICLE_TAB_CLICK',

  // LOAN CALCULATOR
  WEB_LOAN_CALCULATOR_PAGE_VIEW = 'WEB_LOAN_CALCULATOR_PAGE_VIEW',
  WEB_LOAN_CALCULATOR_PAGE_CITY_TOOLTIP_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CITY_TOOLTIP_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_CITY_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CITY_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_MODEL_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_MODEL_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_VARIANT_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_VARIANT_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_INCOME_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_INCOME_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_DP_AMOUNT_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_DP_AMOUNT_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_DP_PERCENTAGE_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_DP_PERCENTAGE_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_DP_SLIDER_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_DP_SLIDER_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_ANGSURAN_TYPE_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_ANGSURAN_TYPE_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_AGE_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_AGE_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_CALCULATE_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CALCULATE_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_RESULT_VIEW = 'WEB_LOAN_CALCULATOR_PAGE_RESULT_VIEW',
  WEB_LOAN_CALCULATOR_PAGE_TENURE_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_TENURE_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_TENURE_PROMO_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_TENURE_PROMO_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_INSURANCE_TOOLTIP_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_INSURANCE_TOOLTIP_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_INSURANCE_OPTION_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_INSURANCE_OPTION_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_PROMO_SELECT = 'WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_PROMO_SELECT',
  WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_PROMO_UNSELECT = 'WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_PROMO_UNSELECT',
  WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_PROMO_SNK_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_PROMO_SK_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_APPLY_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_PROMO_BOTTOMSHEET_APPLY_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_CHECK_QUALIFICATION_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CHECK_QUALIFICATION_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_CHECK_QUALIFICATION_BANNER_CHECK_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CHECK_QUALIFICATION_BANNER_CHECK_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_CHECK_QUALIFICATION_BANNER_CLOSE_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CHECK_QUALIFICATION_BANNER_CLOSE_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_KUALIFIKASI_KREDIT_COACHMARK_VIEW = 'WEB_LOAN_CALCULATOR_PAGE_KUALIFIKASI_KREDIT_COACHMARK_VIEW',
  WEB_LOAN_CALCULATOR_PAGE_KUALIFIKASI_KREDIT_COACHMARK_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_KUALIFIKASI_KREDIT_COACHMARK_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_DP_TOOLTIP_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_DP_TOOLTIP_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_INSTALLMENT_TYPE_TOOLTIP_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_INSTALLMENT_TYPE_TOOLTIP_CLICK',
  WEB_LOAN_CALCULATOR_PAGE_CHANGE_SELECTION_CLICK = 'WEB_LOAN_CALCULATOR_PAGE_CHANGE_SELECTION_CLICK',

  // footer
  WEB_FOOTER_CLICK = 'WEB_FOOTER_CLICK',

  // HOMEPAGE
  WEB_HOMEPAGE_VIEW = 'WEB_HOMEPAGE_VIEW',
  WEB_HOMEPAGE_CHECK_CREDIT_QUALIFICATION_CLICK = 'WEB_HOMEPAGE_CHECK_CREDIT_QUALIFICATION_CLICK',
  WEB_HOMEPAGE_LOAN_CALCULATOR_CLICK = 'WEB_HOMEPAGE_LOAN_CALCULATOR_CLICK',
  WEB_HOMEPAGE_CAR_SEARCH_BUTTON_CLICK = 'WEB_HOMEPAGE_CAR_SEARCH_BUTTON_CLICK',
  WEB_HOMEPAGE_ADD_FINANCIAL_FILTER_CLICK = 'WEB_HOMEPAGE_ADD_FINANCIAL_FILTER_CLICK',
  WEB_HOMEPAGE_TESTIMONY_CLICK = 'WEB_HOMEPAGE_TESTIMONY_CLICK',
  WEB_CAR_RECOMMENDATION_TAB_CLICK = 'WEB_CAR_RECOMMENDATION_TAB_CLICK',
  WEB_CAR_RECOMMENDATION_CLICK = 'WEB_CAR_RECOMMENDATION_CLICK',
  WEB_CAR_RECOMMENDATION_ALL_CLICK = 'WEB_CAR_RECOMMENDATION_ALL_CLICK',
  WEB_CAR_RECOMMENDATION_CTA_CLICK = 'WEB_CAR_RECOMMENDATION_CTA_CLICK',
  WEB_HOMEPAGE_SMART_SEARCH_FIELD_CLICK = 'WEB_HOMEPAGE_SMART_SEARCH_FIELD_CLICK',
  WEB_HOMEPAGE_ADD_FINANCIAL_FILTER_CLOSE = 'WEB_HOMEPAGE_ADD_FINANCIAL_FILTER_CLOSE',
  WEB_HOMEPAGE_FILTER_DP_ADJUST_PRICE_CLICK = 'WEB_HOMEPAGE_FILTER_DP_ADJUST_PRICE_CLICK',
  WEB_HOMEPAGE_FILTER_APPLY_CLICK = 'WEB_HOMEPAGE_FILTER_APPLY_CLICK',
  WEB_HOMEPAGE_FILTER_BRAND_RESET_CLICK = 'WEB_HOMEPAGE_FILTER_BRAND_RESET_CLICK',
  WEB_HOMEPAGE_SMART_SEARCH_TAB_CLICK = 'WEB_HOMEPAGE_SMART_SEARCH_TAB_CLICK',
  WEB_HOMEPAGE_TESTIMONY_SLIDE = 'WEB_HOMEPAGE_TESTIMONY_SLIDE',
  WEB_PDP_CAR_RECOMMENDATION_CLICK = 'WEB_PDP_CAR_RECOMMENDATION_CLICK',

  // LOGIN PAGE
  WEB_LOGIN_PAGE_VIEW = 'WEB_LOGIN_PAGE_VIEW',
  WEB_LOGIN_PAGE_CTA_CLICK = 'WEB_LOGIN_PAGE_CTA_CLICK',
  WEB_REGISTRATION_PAGE_VIEW = 'WEB_REGISTRATION_PAGE_VIEW',
  WEB_REGISTRATION_PAGE_CTA_CLICK = 'WEB_REGISTRATION_PAGE_CTA_CLICK',
  WEB_REGISTRATION_PAGE_SUCCESS_VIEW = 'WEB_REGISTRATION_PAGE_SUCCESS_VIEW',
  WEB_LOGIN_PAGE_PHONE_CLICK = 'WEB_LOGIN_PAGE_PHONE_CLICK',
  WEB_REGISTRATION_PAGE_NAME_CLICK = 'WEB_REGISTRATION_PAGE_NAME_CLICK',
  WEB_REGISTRATION_PAGE_EMAIL_CLICK = 'WEB_REGISTRATION_PAGE_EMAIL_CLICK',
  WEB_REGISTRATION_PAGE_DOB_CLICK = 'WEB_REGISTRATION_PAGE_DOB_CLICK',
  WEB_REGISTRATION_PAGE_REFERRAL_CLICK = 'WEB_REGISTRATION_PAGE_REFERRAL_CLICK',
  WEB_REGISTRATION_PAGE_NOTIFY_PROMO_SELECT = 'WEB_REGISTRATION_PAGE_NOTIFY_PROMO_SELECT',
  WEB_REGISTRATION_PAGE_SK_SELECT = 'WEB_REGISTRATION_PAGE_SK_SELECT',
  WEB_LOGIN_POPUP_ALERT_VIEW = 'WEB_LOGIN_POPUP_ALERT_VIEW',
  WEB_LOGIN_POPUP_ALERT_CTA_CLICK = 'WEB_LOGIN_POPUP_ALERT_CTA_CLICK',
  WEB_LOGIN_POPUP_ALERT_CLOSE = 'WEB_LOGIN_POPUP_ALERT_CLOSE',

  // PROFILE PAGE
  WEB_PROFILE_PAGE_VIEW = 'WEB_PROFILE_PAGE_VIEW',
  WEB_PROFILE_PAGE_NAME_CLICK = 'WEB_PROFILE_PAGE_NAME_CLICK',
  WEB_PROFILE_PAGE_EMAIL_CLICK = 'WEB_PROFILE_PAGE_EMAIL_CLICK',
  WEB_PROFILE_PAGE_DOB_CLICK = 'WEB_PROFILE_PAGE_DOB_CLICK',
  WEB_PROFILE_PAGE_GENDER_CLICK = 'WEB_PROFILE_PAGE_GENDER_CLICK',

  // PTBC
  WEB_PTBC_LANDING_PAGE_VIEW = 'WEB_PTBC_LANDING_PAGE_VIEW',
  WEB_PTBC_LANDING_PAGE_CREDIT_QUALIFICATION_CLICK = 'WEB_PTBC_LANDING_PAGE_CREDIT_QUALIFICATION_CLICK',
  WEB_PTBC_CREDIT_QUALIFICATION_FORM_PAGE_VIEW = 'WEB_PTBC_CREDIT_QUALIFICATION_FORM_PAGE_VIEW',
  WEB_PTBC_CREDIT_QUALIFICATION_FORM_PAGE_CTA_CLICK = 'WEB_PTBC_CREDIT_QUALIFICATION_FORM_PAGE_CTA_CLICK',
  WEB_PTBC_CREDIT_QUALIFICATION_REVIEW_PAGE_VIEW = 'WEB_PTBC_CREDIT_QUALIFICATION_REVIEW_PAGE_VIEW',
  WEB_PTBC_CREDIT_QUALIFICATION_RESULT_PAGE_VIEW = 'WEB_PTBC_CREDIT_QUALIFICATION_RESULT_PAGE_VIEW',
  WEB_PTBC_INSTANT_APPROVAL_POPUP_VIEW = 'WEB_PTBC_INSTANT_APPROVAL_POPUP_VIEW',
  WEB_PTBC_INSTANT_APPROVAL_POPUP_CONTINUE_CLICK = 'WEB_PTBC_INSTANT_APPROVAL_POPUP_CONTINUE_CLICK',
  WEB_PTBC_KTP_PAGE_PHOTO_VIEW = 'WEB_PTBC_KTP_PAGE_PHOTO_VIEW',
  WEB_PTBC_KTP_PAGE_PHOTO_SUCCESS_VIEW = 'WEB_PTBC_KTP_PAGE_PHOTO_SUCCESS_VIEW',
  WEB_PTBC_KTP_EDIT_PAGE_VIEW = 'WEB_PTBC_KTP_EDIT_PAGE_VIEW',
  WEB_PTBC_KTP_PAGE_CONFIRMATION_VIEW = 'WEB_PTBC_KTP_PAGE_CONFIRMATION_VIEW',
  WEB_PTBC_INSTANT_APPROVAL_FINANCING_COMPANY_VIEW = 'WEB_PTBC_INSTANT_APPROVAL_FINANCING_COMPANY_VIEW',
  WEB_PTBC_INSTANT_APPROVAL_PAGE_CONFIRMATION_VIEW = 'WEB_PTBC_INSTANT_APPROVAL_PAGE_CONFIRMATION_VIEW',
  WEB_PTBC_INSTANT_APPROVAL_PAGE_FINISH_VIEW = 'WEB_PTBC_INSTANT_APPROVAL_PAGE_FINISH_VIEW',

  // DETIK
  WEB_DETIK_LOAN_CALCULATOR_PAGE_VIEW = 'WEB_DETIK_LOAN_CALCULATOR_PAGE_VIEW',
  WEB_DETIK_LOAN_CALCULATOR_PAGE_RESULT_VIEW = 'WEB_DETIK_LOAN_CALCULATOR_PAGE_RESULT_VIEW',

  // Generic Success Page
  WEB_SUCCESS_PAGE_VIEW = 'WEB_SUCCESS_PAGE_VIEW',
  WEB_SUCCESS_PAGE_CTA_CLICK = 'WEB_SUCCESS_PAGE_CTA_CLICK',

  // Transaction History Page
  WEB_TRANSACTION_HISTORY_PAGE_VIEW = 'WEB_TRANSACTION_HISTORY_PAGE_VIEW',
  WEB_TRANSACTION_HISTORY_PAGE_TAB_CLICK = 'WEB_TRANSACTION_HISTORY_PAGE_TAB_CLICK',

  // Astra Employee Package
  WEB_PAKET_KARYAWAN_LANDING_PAGE_VIEW = 'WEB_PAKET_KARYAWAN_LANDING_PAGE_VIEW',
  WEB_PAKET_KARYAWAN_LANDING_PAGE_MAIN_CTA_CLICK = 'WEB_PAKET_KARYAWAN_LANDING_PAGE_MAIN_CTA_CLICK',
  WEB_PAKET_KARYAWAN_LANDING_PAGE_SECONDARY_CTA_CLICK = 'WEB_PAKET_KARYAWAN_LANDING_PAGE_SECONDARY_CTA_CLICK',
  WEB_PAKET_KARYAWAN_CAR_SELECTION_PAGE_VIEW = 'WEB_PAKET_KARYAWAN_CAR_SELECTION_PAGE_VIEW',
  WEB_PAKET_KARYAWAN_CAR_SELECTION_PAGE_CTA_CLICK = 'WEB_PAKET_KARYAWAN_CAR_SELECTION_PAGE_CTA_CLICK',
  WEB_PAKET_KARYAWAN_LOAN_CALCULATOR_PAGE_VIEW = 'WEB_PAKET_KARYAWAN_LOAN_CALCULATOR_PAGE_VIEW',
  WEB_PAKET_KARYAWAN_LOAN_CALCULATOR_PAGE_CTA_CLICK = 'WEB_PAKET_KARYAWAN_LOAN_CALCULATOR_PAGE_CTA_CLICK',
  WEB_PROMO_KARYAWAN_ASTRA_PAGE_VIEW = 'WEB_PROMO_KARYAWAN_ASTRA_PAGE_VIEW',
  WEB_PROMO_KARYAWAN_ASTRA_PAGE_BANNER_CLICK = 'WEB_PROMO_KARYAWAN_ASTRA_PAGE_BANNER_CLICK',

  // Marketing
  WEB_MARKETING_LEADS_FORM_PAGE_VIEW = 'WEB_MARKETING_LEADS_FORM_PAGE_VIEW',
  WEB_MARKETING_LEADS_FORM_PAGE_CTA_CLICK = 'WEB_MARKETING_LEADS_FORM_PAGE_CTA_CLICK',

  //CRM Engine
  WEB_SPRE_LANDING_PAGE_VIEW = 'WEB_SPRE_LANDING_PAGE_VIEW',
  WEB_SPRE_LANDING_PAGE_CTA_CLICK = 'WEB_SPRE_LANDING_PAGE_CTA_CLICK',
  WEB_SPRE_PROMO_RESULT_PAGE_VIEW = 'WEB_SPRE_PROMO_RESULT_PAGE_VIEW',
  WEB_SPRE_PROMO_RESULT_PAGE_CTA_CLICK = 'WEB_SPRE_PROMO_RESULT_PAGE_CTA_CLICK',

  // CAR COMPARISON
  WEB_CAR_COMPARISON_LANDING_PAGE_VIEW = 'WEB_CAR_COMPARISON_LANDING_PAGE_VIEW',
  WEB_CAR_COMPARISON_RESULT_PAGE_VIEW = 'WEB_CAR_COMPARISON_RESULT_PAGE_VIEW',
  WEB_CAR_COMPARISON_LANDING_PAGE_CTA_CLICK = 'WEB_CAR_COMPARISON_LANDING_PAGE_CTA_CLICK',
  WEB_CAR_COMPARISON_ADD_CAR_CLICK = 'WEB_CAR_COMPARISON_ADD_CAR_CLICK',

  // EVENT ASTRA SIAGA LEBARAN
  WEB_POPUP_POS_CLICK = 'WEB_POPUP_POS_CLICK',
  WEB_SEARCH_POS_LOCATION = 'WEB_SEARCH_POS_LOCATION',

  // Dealer
  WEB_DEALER_PAGE_VIEW = 'WEB_DEALER_PAGE_VIEW',
  WEB_DEALER_BRAND_PAGE_VIEW = 'WEB_DEALER_BRAND_PAGE_VIEW',
  WEB_DEALER_CITY_PAGE_VIEW = 'WEB_DEALER_CITY_PAGE_VIEW',
  WEB_DEALER_BRANCH_PAGE_VIEW = 'WEB_DEALER_BRANCH_PAGE_VIEW',
}
